<template>
    <div style="margin-top:5px;">
        <h6>{{$parent.lang.invoice_expenses}}</h6>
        <v-simple-table striped hover style="width:100%;">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th>{{$parent.lang.item_code}}</th>
                        <th style="width:40%;">{{$parent.lang.description}}</th>
                        <th>{{$parent.lang.qtty}}</th>
                        <th>{{$parent.lang.item_price}}</th>
                        <th>{{$parent.lang.total}}</th>
                        <th>{{$parent.lang.action}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,index) in exprows" :key="index">
                        <td>{{item.itemid}}</td>
                        <td>{{item.description}}</td>
                        <td>{{item.qty}}</td>
                        <td>{{item.price}}</td>
                        <td>{{item.total}}</td>
                        <td>
                            <v-btn class="ma-1" style="height:25px;background:red;color:#FFF;width:70px"  @click="DeleteLabor( item.id,index )">{{$parent.lang.delete}}</v-btn>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>{{$parent.lang.totals}}</th>
                        <th>{{exptotals}}</th>
                        <th></th>
                    </tr>
                    <tr>
                        <td>
                            <b-input v-model="itemid" id="_id" @change="getProducts()" style="font-size: 0.8rem;width:100px"></b-input>
                        </td>
                        <td>
                            <b-input v-model="description" id="_description" style="font-size: 0.8rem"></b-input>
                        </td>
                        <td>
                            <b-input v-model="qty" style="font-size: 0.8rem"></b-input>
                        </td>
                        <td>
                            <b-input v-model="price" style="font-size: 0.8rem"></b-input>
                        </td>
                        <td>
                            <b-input v-model="total" readonly style="font-size: 0.8rem"></b-input>
                        </td>
                        <td>
                            <v-btn class="ma-1" style="height:25px;background:green;color:#FFF;width:70px" @click="addexpens()">{{$parent.lang.add}}</v-btn>
                        </td>
                    </tr>
                </tfoot>
            </template>
        </v-simple-table>

        <vue-snotify></vue-snotify>
    </div>
</template>

<script>
import axios from 'axios'
import {SnotifyPosition} from 'vue-snotify';
export default {
    data() {
        return {
            itemid: '',
            description:'',
            price: '',
            qty: 1,
            exprows: [],
        }
    },
    computed: {
        total: function(){
            let t = 0;
            if(this.price == '' || this.qty == '')
            {
                return 0;
            }
            t = parseFloat(this.qty) * parseFloat(this.price);
            return this.$RoundNum(t);
        },
        exptotals: function(){
            let t = 0;
            for(let i=0;i<this.exprows.length;i++){
                t = parseFloat(t) + parseFloat(this.exprows[i].total);
            }
            return this.$RoundNum(t);
        }
    },
    created() {
        this.getexpense();
    },
    methods: {
        DeletExpens(){
            //
        },
        addexpens(){
            if(this.description == ''){
                this.$snotify.error('يرجى اضافة وصف للمصروفات', '', {
                    timeout: 5000,
                    showProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: true,
                    leftTop:"leftTop"
                    });
                    return false;
            }
            if(this.qty == '' || this.price == ''){
                this.$snotify.error('يرجى اضافة سعر وكمية للمصروفات', '', {
                    timeout: 5000,
                    showProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: true,
                    leftTop:"leftTop"
                    });
                    return false;
            }
            const post = new FormData();
            post.append("type","addInvoiceExpens");
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[invid]',this.$route.params.id);
            post.append('data[itemid]',this.itemid);
            post.append('data[description]',this.description);
            post.append('data[qty]',this.qty);
            post.append('data[price]',this.price);
            post.append('data[total]',this.total);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    console.log(res);
                    this.getexpense()
                    this.itemid = '';
                    this.description = '';
                    this.price = '';
                    this.qty = 1;
                }
            )
        },
        getexpense(){
            const post = new FormData();
            post.append("type","getInvoiceExpens");
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[invid]',this.$route.params.id);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    console.log(res);
                    this.exprows = res.results.data;
                }
            )
        },
        getProducts() {
            const post = new FormData();
            post.append("type","getProducts");
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[item_number]',this.itemid);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                
                if(response.data.results.data.length > 0){
                    this.itemid = response.data.results.data[0].itemcode;
                    this.description = response.data.results.data[0].name;
                    this.price = response.data.results.data[0].price;
                    this.qty = 1;
                    
                }
            })
        },
    },
}
</script>