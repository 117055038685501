<template>
    <div class="m-2" style="margin-bottom:50px !important;">
        <bread-crumbs :items='items'/>
            <v-row style="margin:auto;width:80%">
                <v-col cols="12">
                    <b-button class="mytab1 btn btn-sm btn-success m-2" style="width:120px;" @click="printPDF($route.params.id)">{{lang.print_invoice}}</b-button> 
                    <b-button class="mytab1 btn btn-sm btn-success m-2" style="width:120px;" @click="printInvo(d)">{{lang.view_pdf}}</b-button> 
                    <!-- <b-button v-if="invoice.status > 0 && (parseFloat(invoice.cancel_by) == 0 || parseFloat(invoice.cancel_by).toString() == 'NaN')" style="background:darkred;width:100px;" class="mytab2 btn btn-sm btn-danger m-2" @click="returnNow(1)" v-b-toggle.returnInvoice>ارجاع المحدد</b-button>  -->
                    <b-button v-if="invoice.status > 0 && (parseFloat(invoice.cancel_by) == 0 || parseFloat(invoice.cancel_by).toString() == 'NaN')" style="background:#42151a;width:120px;" class="mytab2 btn btn-sm btn-danger m-2" @click="returnNow(0)" v-b-toggle.returnInvoice>{{lang.invoice_return}}</b-button> 
                    <b-button v-if="invoice.remain > 0 && (parseFloat(invoice.cancel_by) == 0 || parseFloat(invoice.cancel_by).toString() == 'NaN')" style="background:#008800;width:120px;" class="mytab2 btn btn-sm btn-success m-2" v-b-toggle.addPayments>{{lang.make_payment}}</b-button> 
                </v-col>
                <b-table-simple class="m-2">
                    <tbody>
                        <tr>
                            <th style="border:1px solid #555;text-align:center;background:#27467b;color:#FFF;width:20%">{{lang.invoiceno}}</th>
                            <td style="border:1px solid #555;text-align:center;width:13.33%">{{ invoice.invoice_number }}</td>
                            <th style="border:1px solid #555;text-align:center;background:#27467b;color:#FFF;width:20%">{{lang.invoice_date}}</th>
                            <td style="border:1px solid #555;text-align:center;width:13.33%">{{ invoice.inv_date }}</td>
                            <th style="border:1px solid #555;text-align:center;background:#27467b;color:#FFF;width:20%">{{lang.invoice_tme}}</th>
                            <td style="border:1px solid #555;text-align:center;width:13.33%">{{ invoice.inv_date }}</td>
                        </tr>
                    </tbody>
                </b-table-simple>
                <b-table-simple class="m-2">
                    <tbody>
                        <tr>
                            <th style="border:1px solid #555;text-align:center;background:#27467b;color:#FFF;width:20%"> {{lang.invoice_type}}</th>
                            <td style="border:1px solid #555;text-align:center;width:13.33%">{{ invoice._invtype }}</td>
                            <th style="border:1px solid #555;text-align:center;background:#27467b;color:#FFF;width:20%">{{lang.paid}}</th>
                            <td style="border:1px solid #555;text-align:center;width:13.33%">{{ invoice.paid }}</td>
                            <th style="border:1px solid #555;text-align:center;background:#27467b;color:#FFF;width:20%">{{lang.remain}}</th>
                            <td style="border:1px solid #555;text-align:center;width:13.33%">{{ invoice.remain }}</td>
                        </tr>
                    </tbody>
                </b-table-simple>
                <b-table-simple class="m-2">
                    <tbody>
                        <tr v-if="invoice.cancel_by">
                            <th style="border:1px solid #555;text-align:center;background:#27467b;color:#FFF;width:20%">{{lang.return_date}}</th>
                            <td style="border:1px solid #555;text-align:center;width:13.33%">{{ invoice.cancel_date }}</td>
                            <th style="border:1px solid #555;text-align:center;background:#27467b;color:#FFF;width:20%">{{lang.return_time}}</th>
                            <td style="border:1px solid #555;text-align:center;width:13.33%">{{ invoice.cancel_date  }}</td>
                            <th style="border:1px solid #555;text-align:center;background:#27467b;color:#FFF;width:20%">{{lang.return_case}}</th>
                            <td style="border:1px solid #555;text-align:center;width:13.33%">{{ invoice.cancel_reson }}</td>
                        </tr>
                    </tbody>
                </b-table-simple>
                <b-table-simple class="m-2">
                    <tbody>
                        <tr>
                            <th style="padding:2px;height:10px;border:1px solid #555;text-align:center;background:#27467b;color:#FFF;">{{lang.customer_name}}</th>
                            <td style="padding:2px;height:10px;border:1px solid #555;text-align:center;">{{ invoice.full_name }}</td>
                            <th style="padding:2px;height:10px;border:1px solid #555;text-align:center;background:#27467b;color:#FFF;">{{lang.mobile}}</th>
                            <td style="padding:2px;height:10px;border:1px solid #555;text-align:center;">{{ invoice.mobile }}</td>
                        </tr>
                        <tr v-if="invoice.company_name != null && invoice.company_name != ''">
                            <th style="padding:2px;height:10px;border:1px solid #555;text-align:center;background:#27467b;color:#FFF;">{{lang.company_name}}</th>
                            <td style="padding:2px;height:10px;border:1px solid #555;text-align:center;">{{ invoice.company_name }}</td>
                            <th style="padding:2px;height:10px;border:1px solid #555;text-align:center;background:#27467b;color:#FFF;">{{lang.company_vatid}}</th>
                            <td style="padding:2px;height:10px;border:1px solid #555;text-align:center;">{{ invoice.vatid }}</td>
                        </tr>
                        <tr v-if="$store.state.licenseType.cars || $store.state.licenseType.parts || $store.state.licenseType.carinfo">
                            <th style="padding:2px;height:10px;border:1px solid #555;text-align:center;background:#27467b;color:#FFF;">{{lang.plate_number}}</th>
                            <td style="padding:2px;height:10px;border:1px solid #555;text-align:center;">{{ invoice.plate_number }}</td>
                            <th style="padding:2px;height:10px;border:1px solid #555;text-align:center;background:#27467b;color:#FFF;">{{lang.car_model}}</th>
                            <td style="padding:2px;height:10px;border:1px solid #555;text-align:center;">{{ invoice.model }}</td>
                        </tr>
                        <tr v-if="$store.state.licenseType.cars || $store.state.licenseType.parts">
                            <th style="padding:2px;height:10px;border:1px solid #555;text-align:center;background:#27467b;color:#FFF;">{{lang.car_year}}</th>
                            <td style="padding:2px;height:10px;border:1px solid #555;text-align:center;">{{ invoice.manufacturing_year }}</td>
                            <th style="padding:2px;height:10px;border:1px solid #555;text-align:center;background:#27467b;color:#FFF;">{{lang.car_color}}</th>
                            <td style="padding:2px;height:10px;border:1px solid #555;text-align:center;">{{ invoice.color }}</td>
                        </tr>
                    </tbody>
                </b-table-simple>
            </v-row>
            <v-row style="margin:auto;width:80%">
                <h6>{{lang.invoice_details}}</h6>
                <b-table-simple class="m-2">
                    <thead>
                        <tr>
                            <!-- <th v-if="returnIt" style="text-align:center;width:50px;">
                                
                            </th> -->
                            <th style="text-align:center;">{{lang.item_code}}</th>
                            <th style="text-align:center;">{{lang.description}}</th>
                            <th style="text-align:center;">{{lang.qtty}}</th>
                            <th style="text-align:center;">{{lang.price}}</th>
                            <th style="text-align:center;">{{lang.total}}</th>
                            <th style="text-align:center;">{{lang.vat}} 15%</th>
                            <th style="text-align:center;">{{lang.ftotal}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in invoice.rows" :key="index">
                            <!-- <td style="text-align:center;" v-if="returnIt">
                                <input type="checkbox" v-if="item.status > 0" :value="item.id" @click="selThis(item)">
                            </td> -->
                            <td style="text-align:center;">{{item.itemcode}}</td>
                            <td style="text-align:center;">{{item.item_name}}</td>
                            <td style="text-align:center;">{{item.qty}}</td>
                            <td style="text-align:center;">{{item.item_price}}</td>
                            <td style="text-align:center;">{{item.total}}</td>
                            <td style="text-align:center;">{{item.vat}}</td>
                            <td style="text-align:center;">{{item.ftotal}}</td>
                        </tr>
                    </tbody>
                </b-table-simple>
                <b-table-simple style="width:400px;margin:auto;margin-inline-end:-5px !important;">
                    <thead>
                        <tr>
                            <th class="text-end">{{lang.total_exclusive_vat}}</th>
                            <td style="width:25%;border:1px solid #ccc;text-align:center;">{{invoice.total}}</td>
                        </tr>
                        <tr>
                            <th class="text-end">{{lang.vat}} 15%</th>
                            <td style="width:25%;border:1px solid #ccc;text-align:center;">{{invoice.vat}}</td>
                        </tr>
                        <tr>
                            <th class="text-end">{{lang.total_include_vat}}</th>
                            <td style="width:25%;border:1px solid #ccc;text-align:center;">{{invoice.ftotal}}</td>
                        </tr>
                    </thead>
                </b-table-simple>
            </v-row>
            <v-row style="margin:auto;width:80%">
                <h6>{{lang.payments}}</h6>
                <b-table-simple class="m-2">
                    <thead>
                        <tr>
                            <th style="text-align:center;">{{lang.payment_date}}</th>
                            <th style="text-align:center;">{{lang.payment_time}}</th>
                            <th style="text-align:center;">{{lang.payment_amount}}</th>
                            <th style="text-align:center;">{{lang.payment_cash}}</th>
                            <th style="text-align:center;">{{lang.payment_span}} / {{lang.payment_bank}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in invoice.res" :key="index">
                            <td style="text-align:center;">{{item.created_date}}</td>
                            <td style="text-align:center;">{{item.created_date}}</td>
                            <td style="text-align:center;">{{item.ftotal}}</td>
                            <td style="text-align:center;">{{item.paycash}}</td>
                            <td style="text-align:center;">{{item.payspan}}</td>
                        </tr>
                    </tbody>
                </b-table-simple>
            </v-row>
            <v-row style="margin:auto;width:82%">
                <invoice-expenses />
            </v-row>
        <vue-snotify></vue-snotify>

        <b-sidebar v-if="selItems.length != 0" id="returnInvoice" aria-labelledby="sidebar-no-header-title" no-header width="50rem" backdrop style="direction:ltr" right shadow >
            <template #default="{ hide }">
                
            <v-form
                ref="forinv"
                lazy-validation
            >
                <div class="m-1 text-start" :style="`direction:`+lang.dir">
                    <div style="width:120px;margin-right:10px;background:#28467c;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                        <span>{{lang.return_invoice}}</span>
                    </div>
                    <div @click="hide" id="HideMe" style="float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                        <span>{{lang.close}}</span>
                    </div>
                </div>
                <v-row class="text-center p-3 m-3" style="background:#EEE;border:1px solid #ccc;">
                    <v-col cols="6">
                        <b-button class="btn-danger btn-sm">{{lang.return_amount}}: {{returntotal}}</b-button>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row :style="`direction:`+lang.dir+`;margin:2px;`" class="m-2">
                    <v-col cols="12" md="4" sm="12">
                        <v-text-field
                            v-model="mobile"
                            type="number"
                            :label="lang.mobile"
                            :rules="fieldRules"
                        >{{invoice.mobile}}</v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                        <v-text-field
                            v-model="full_name"
                            :label="lang.customer_name"
                            :rules="fieldRules"
                        >{{invoice.full_name}}</v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                        <v-select
                            item-text="text"
                            item-value="value"
                            v-model="payMethod"
                            :items="payMethods"
                            :label="lang.payment_method"
                            :rules="fieldRules"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                        <v-textarea
                            v-model="reson"
                            :label="lang.return_case"
                            :rules="fieldRules"
                        ></v-textarea>
                    </v-col>
                </v-row>
            </v-form>
            </template>

            <template #footer="{ hideit }" class="shadow">
                <div class="d-flex text-light align-items-center px-3 py-2">
                <b-button type="button" @click="reItemNow();hideit" variant="success" class="ma-2" style="width:100px;">{{lang.return}}</b-button>
                </div>
            </template>
        </b-sidebar>
        <invPayment :amount1='invoice.remain' :mobile1="mobile" :full_name1="full_name" />
    </div>
</template>

<script>
import breadCrumbs from '@/components/breadCrumbs.vue';
import invPayment from '@/components/invPayment.vue'; 
import axios from 'axios'
import {SnotifyPosition} from 'vue-snotify';
import InvoiceExpenses from '@/components/invoice-expenses.vue';
export default{
  components: { breadCrumbs,invPayment,InvoiceExpenses },
    data() {
        return {
            fieldRules: [
                v => !!v || this.lang.required_field
            ],
            mobile: '',
            amount: '',
            full_name: '',
            reson: [],
            payMethod: 1,
            spayMethod: 1,
            payMethods: [
                {text: 'Cash Payment', value: 1},
                {text: 'Bank Transfer', value: 2},
            ],
            payMethodss: [
                {text: 'Cash Payment', value: 1},
                {text: 'Span Payment', value: 2},
            ],
            returnIt: true,
            selectALL: false,
            startreturn: false,
            
            invoice: [],
            selItems: [],
            showSidebar: false,
            rItems: []
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        items: function() {
            return {
                    text: this.lang.view_invoice,
                    disabled: true,
                    href: '/view-invoice/',
                }
        },
        returntotal: function(){
            let total = 0;
            if(this.selItems.length > 0){
                for(let i=0;i<this.selItems.length;i++){
                    total = parseFloat(total) + parseFloat(this.selItems[i].ftotal);
                }
            }
            if(this.invoice.remain != 0 && this.invoice.remain != 'NaN'){
                total = this.invoice.paid;
            }
            return total;
        }
    },
    methods: {
        returnNow(i){
            if (this.$snotify.notifications.length > 0) {
                this.$snotify.notifications.forEach(notification => {
                    this.$snotify.remove(notification.id)
                });
            }
            if(i == 1){
                if(this.selItems.length == 0 || 
                    (parseInt(this.selItems[0]) === parseInt('0') && this.selItems.length == 1)){
                    this.$snotify.error('يرجى اختيار عنصر أو اكثر من الفاتورة ليتم ارجاعها'  , {
                        timeout: 1000000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                    });
                    this.showSidebar = false;
                    return false;
                }
            }
            if(i == 0){
                
                    this.selAll();
            }
            this.showSidebar = true;
        },
        reItemNow(){
            
            const post = new FormData();
            post.append('type','returnMyInvoice');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[invid]',this.$route.params.id);
            post.append('data[full_name]',this.full_name);
            post.append('data[mobile]',this.mobile);
            post.append('data[reson]',this.reson);
            post.append('data[paytype]',this.payMethod);
            for(let i=0;i<this.selItems.length;i++){
                post.append('data[items][]',this.selItems[i].id)
            }
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                console.log("lll",res);
                window.open("../api/print.php?invid="+res.results.data,"_blank");

            }).then(() => {
               this.$router.push({path: "/invoicelist"})
            })
        },
        printInvo(){
            window.open("../api/print.php?invid="+this.$route.params.id,"_blank");
        },
        selThis(id){
            if(this.selItems.includes(id)){
                for( var i = 0; i < this.selItems.length; i++){ 
                    if ( this.selItems[i] === id) { 
                        this.selItems.splice(i, 1); 
                    }
                    if(this.selItems.length == 0){
                        this.startreturn = false;
                    }
                }
            }else{
                this.selItems.push(id);
                this.startreturn = true;
            }
        },
        selAll() {
            this.selItems = this.invoice.rows;
        },
        getInvoice(){
            const post = new FormData();
            post.append('type','getinvoicedet');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[invid]',this.$route.params.id);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                console.log(res);
                this.invoice = res.results.data;
                this.mobile = this.invoice.mobile
                this.full_name = this.invoice.full_name
                this.amount = this.invoice.remain
                
            })
        },
        printPDF (id)
        {
            let pdfFrame = document.body.appendChild(document.createElement('iframe'));
            pdfFrame.style.display = 'none';
            pdfFrame.onload = () => (void pdfFrame.contentWindow.print());
            pdfFrame.src = '../api/print.php?invid='+id;
        },
    },
    created() {
        this.getInvoice();
    },
}
</script>